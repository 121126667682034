import React, { Fragment, useState, useRef, useEffect, useContext } from "react"
import PropTypes from "prop-types"
import { FormattedMessage, Link, navigate, useIntl } from "gatsby-plugin-intl"
import { faCircleInfo } from "@fortawesome/pro-regular-svg-icons/faCircleInfo"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { ACCOUNT_TYPE, OFFER_TYPE, userTypeShort } from "@tmu/apollo/constants"

import { getAllScreenTypes } from "@tmu/utils/mediaQueries"
import {
  Button,
  TextInput,
  CheckboxContainer,
  Money,
  FormErrors,
  TermsAndConditions,
  Spinner,
  Logo,
  CreditCardSelector,
  FieldError,
  PaymentMethods,
  SocialLoginComponent,
  RadioSelect,
  OnSubmitValidationError,
  AmountProvisioner,
} from "@tmu/components/common"
import SingleDonation from "./SingleDonation"
import { useAuth, useToast } from "@tmu/hooks"
import { useQuery, useMutation } from "@apollo/client"
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js"
import { Tooltip } from "react-tooltip"
import { setCredentials } from "@tmu/utils/auth"

import {
  FormWrapper,
  StyledPageWrapper,
  StyledLabel,
  StyledFormRow,
  StyledPACWrapper,
  StyledMoney,
  StyledErrorMessage,
  StyledAgreeTerms,
  StyledButtonContainer,
  StyledUseAnotherCardButton,
  StyledEmailLabel,
  Styled3DInfoText,
  StyledCardErrorWrapper,
  StyledCheckboxLine,
  StyledFlexBox,
  StyledBlueText,
  StyledTitle,
  StyledSectionBox,
  StyledFlexFormRow,
  StyledWarningTitle,
  StyledLoginActions,
  StyledMobileGrayBox,
  StyledRegisterBox,
  StyledFlexBoxRow,
} from "./index.styles"
import { PartialForOfflineDonation } from "./PartialForOfflineDonation"
import {
  CREATE_DONATION_MUTATION,
  SUBSCRIPTION_MUTATION,
} from "@tmu/apollo/storefront/mutations/campaign"
import { Formik, Form } from "formik"
import * as Yup from "yup"
import {
  paymentAmount,
  cardholderName,
  agreeTerms,
  firstName,
  displayName,
  email as emailValidation,
  fiscalCode,
  vat,
  note,
  phone,
  password,
  campanyName,
} from "@tmu/utils/validation"
import { cardElementStyles } from "@tmu/global/GlobalStyle"
import { useCurrency, useCreditCards, useUserExists } from "@tmu/hooks"
import {
  MERCHANT_PAC_RECONCILIATION_STRATEGY,
  PAYMENT_TYPES,
  RECURRING_DONATION_TYPE,
  REQUIRED_FIELD_SYMBOL,
} from "@tmu/apollo/constants"
import { isTokenExpired } from "@tmu/utils/auth"
import { get } from "@tmu/utils/storage"
import theme from "@tmu/global/theme"
import queryString from "query-string"
import { usePacDiscountCalculation } from "@tmu/hooks"
import { HomePageContext } from "@tmu/context/homePageContext"
import { StyledSectionTitle } from "../ServicePurchaseForm/index.styles"
import { useDonations, usePayment } from "@tmu/src/hooks"
import { Spacer } from "@tmu/src/global/page-addons/detail-page.styles"

const pacsLogo =
  process.env.CLOUDFLARE_IMAGE_URL + "/static/assets/images/pac.svg"

const pacsLogoContent = (
  <img
    className="pacs-image"
    src={pacsLogo}
    alt={`pacs`}
    width={16}
    height={16}
  />
)

const DonationForm = ({
  amount,
  campaign,
  redirectTo,
  merchantId,
  onComplete,
  isAmountFixed,
  amountMin,
  amountMax,
  selectedTier,
  pacMultiplier,
  subscriptionProductsId,
}) => {
  const [isPayPalProcessing, setIsPaypalProcessing] = useState(false)
  const { error: errorToaster } = useToast()
  const stripe = useStripe()
  const { formatMessage, locale } = useIntl()
  const [isQuantityAvailable, setIsQuantityAvailable] = useState(true)

  const {
    user,
    signIn,
    loading,
    setIsAuthenticated,
    callUserProfile,
    updateCommunicationLanguageWithBrowser,
  } = useAuth()

  const params = queryString.parse(location.search, { arrayFormat: "comma" })
  const [isCompany, setIsCompany] = useState(user?.accountType === "BUSINESS")
  const [showLogin, setShowLogin] = useState(false)
  const { checkUserExist, doesUserExist } = useUserExists()
  const [alreadyHaveAccount, setAlreadyHaveAccount] = useState(false)
  const [quantity, setQuantity] = useState(1)
  const [expectedQuantity, setExpectedQuantity] = useState(1)
  const { callDonation } = useDonations()
  const [donationProvision, setDonationProvision] = useState()
  const referralCode = params.referral
  useEffect(() => {
    if (doesUserExist) {
      setAlreadyHaveAccount(true)
      setShowLogin(true)
    }
  }, [doesUserExist])
  const [
    createNewSubscription,
    { error: subscriptionError, data: subscriptionData },
  ] = useMutation(SUBSCRIPTION_MUTATION)

  const emailInformationText = formatMessage({
    id: "donation::emailInformationText",
    defaultMessage: "We will send the donation receipt to this address",
  })
  const createSubscription = (
    subscriptionProduct,
    amount,
    interval,
    stripePaymentMethodId,
    isAnonymous
  ) => {
    return createNewSubscription({
      variables: {
        input: {
          subscriptionProduct,
          amount,
          currency: process.env.DEFAULT_CURRENCY || currency,
          interval,
          stripePaymentMethodId,
          isAnonymous: !!isAnonymous,
        },
      },
    })
  }
  const handleClickLoginLink = () => {
    setShowLogin(true)
  }
  const handleSubscriptionResult = (data, recurringData) => {
    const { subscription, errors } = { ...data?.createSubscription }
    if (errors?.length) {
      const errorMessage = errors?.[0].messages[0]
      errorToaster(errorMessage)
      throw new Error(errorMessage)
    }

    recurringData.subscriptionId = subscription?.id
    recurringData.donation = subscription?.plan
    recurringData.donation.earnedPac =
      Number(subscription?.earnedPac) ||
      Number(subscription?.plan?.amount || 0) *
        Number(subscription?.campaign?.pacMultiplier || 0) ||
      0
    recurringData.campaign = subscription?.campaign

    return recurringData
  }

  const processPaypal = async (params) => {
    const setupIntent = params?.setup_intent
    if (params?.payment_intent || setupIntent) {
      if (params?.redirect_status === "succeeded") {
        setIsPaypalProcessing(true)
        delete params.redirect_status
        const rollbackUrl = new URL(location.href)
        rollbackUrl.searchParams.delete("redirect_status")
        rollbackUrl.searchParams.delete("payment_intent")
        rollbackUrl.searchParams.delete("payment_intent_client_secret")
        window?.history?.pushState({}, null, rollbackUrl.toString())
        if (setupIntent) {
          try {
            const stripeData = await stripe.retrieveSetupIntent(
              params?.setup_intent_client_secret
            )
            const data = await createSubscription(
              params?.subscriptionProduct,
              params?.amount,
              params?.interval,
              stripeData?.setupIntent?.payment_method,
              params.isAnonymous
            )

            const recurringData = handleSubscriptionResult(data?.data, {})
            onComplete({
              recurringData,
              reconId: params?.reconId,
              paymentMethod: PAYMENT_TYPES.PAYPAL,
              stripe,
              paymentType: PAYMENT_TYPES.PAYPAL,
            })
          } finally {
            // setIsPaypalProcessing(false)
          }
        } else {
          const donationId = params?.donationId

          callDonation({ variables: { id: donationId } }).then((result) => {
            onComplete({
              paymentMethod: PAYMENT_TYPES.PAYPAL,
              stripe,
              paymentType: PAYMENT_TYPES.PAYPAL,
              donationId,
              donationData: result?.data?.donation,
              confirmPaymentMethod,
            })
          })
        }
      } else if (params?.redirect_status === "failed") {
        const paymentIntentClientSecret = params?.payment_intent_client_secret
        const rollbackUrl = new URL(location.href)
        rollbackUrl.searchParams.delete("redirect_status")
        rollbackUrl.searchParams.delete("payment_intent")
        rollbackUrl.searchParams.delete("payment_intent_client_secret")
        window?.history?.pushState({}, null, rollbackUrl.toString())
        const result = await stripe.retrievePaymentIntent(
          paymentIntentClientSecret
        )

        const errorData =
          result?.error ?? result?.paymentIntent?.last_payment_error?.message

        if (errorData) {
          errorToaster(errorData)
        }
      }
    }
  }
  if (stripe) {
    processPaypal(params)
  }

  const isAuthenticated = !isTokenExpired(get("token"))
  const { currency } = useCurrency()
  const campaignID = campaign?.id
  const { confirmPaymentMethod, confirmSetupMethod } = usePayment()
  const [selectedPaymentType, setSelectedPaymentType] = useState(
    PAYMENT_TYPES.CARD
  )
  const isPayPal = selectedPaymentType === PAYMENT_TYPES.PAYPAL
  const isBankTransfer = selectedPaymentType === PAYMENT_TYPES.CUSTOMER_BALANCE
  const {
    callCreateClientSecret,
    clientSecret,
    callAllSavedAccounts,
    allSavedAccountsData,
    allCards,
    callCreateClientSecretWithRegisterMutation,
  } = useCreditCards({
    callImmediate: false,
  })
  const elements = useElements({ locale })
  const creditCardRef = useRef()
  const creditCardRefError = useRef()
  const [processing, setProcessing] = useState(false)
  const [cardError, setCardError] = useState()
  const [selectedCard, setSelectedCard] = useState()
  const [useSavedCard, setUseSavedCard] = useState(isAuthenticated)
  const { success } = useToast()
  const contextData = useContext(HomePageContext)
  const { getPacReconDetail, pacReconDetail, detailCalled, detailLoading } =
    usePacDiscountCalculation()
  const { email, country } = { ...user }
  const { isTablet, isWide } = getAllScreenTypes()
  const [stripeErrorData, setStripeErrorData] = useState(null)
  const [isCardEmpty, setIsCardEmpty] = useState(true)
  const [submittedOnce, setSubmittedOnce] = useState(false)
  const [loginLoading, setLoginLoading] = useState(false)
  const { donationType, reconId } = params
  const isRecurring =
    donationType === RECURRING_DONATION_TYPE.MONTHLY ||
    donationType === RECURRING_DONATION_TYPE.ANNUALLY
  const hasReconId = reconId?.length > 0
  const isSameCampaign = pacReconDetail?.campaign?.id === campaignID
  const campaignCheckRequired = hasReconId && detailCalled

  if (hasReconId) {
    isAmountFixed = true
  }
  useEffect(() => {
    if (hasReconId) {
      getPacReconDetail({ variables: { id: reconId } })

      if (campaignCheckRequired && campaignID && !isSameCampaign) {
        navigate(
          `/campaigns/${pacReconDetail?.campaign?.slug || campaign?.slug}`
        )
      }
    }
  }, [])

  const isOfflinePacDiscount = pacReconDetail?.campaign?.id
  const isOfflineMerchantCampaign =
    campaign?.store?.defaultOffer?.offerType === OFFER_TYPE.OFFLINE

  const isLowerThenMinDonation = Number(pacReconDetail?.donationAmount) < 5

  const discountPaymentAmount = isLowerThenMinDonation
    ? 5
    : Number(pacReconDetail?.donationAmount)

  const userBalance = contextData?.globalPacBalance

  const isEventCampaign = campaign?.campaignType === "EV"
  const tier =
    campaign?.tiers?.edges?.length === 1
      ? campaign?.tiers?.edges?.[0]?.node
      : campaign?.tiers?.edges?.filter(
          ({ node }) => node?.id === selectedTier
        )?.[0]?.node

  const showAmountProvision =
    isEventCampaign &&
    isAmountFixed &&
    (tier?.isUnlimitedTickets || tier?.maxTickets > 0)

  const isOfflineNoDonationNeeded =
    pacReconDetail?.strategy ===
    MERCHANT_PAC_RECONCILIATION_STRATEGY.NO_DONATION_NEEDED

  const amountData = discountPaymentAmount || amount

  let validationObject = {
    firstName: isCompany
      ? campanyName({ formatMessage })
      : firstName({ formatMessage }),
    lastName: isCompany ? null : displayName({ formatMessage }),
    paymentAmount: paymentAmount({
      formatMessage,
      minValue: amountMin,
      maxValue: amountMax,
    }),
    cardholderName:
      !isPayPal &&
      !isBankTransfer &&
      !useSavedCard &&
      cardholderName({ formatMessage }),
    agreeTerms: agreeTerms({ formatMessage }),
    note: note({ formatMessage }),
    // phoneNumber:
    //   !isAuthenticated &&
    //   campaign?.campaignType === "EV" &&
    //   phone({ formatMessage }),
    // taxId: isCompany && vat({ formatMessage }),
  }

  let initialValues = {
    paymentAmount: amountData,
    cardholderName: "",
    note: "",
    isAnonymous: false,
    agreeTerms: false,
    saveCard: false,
    firstName: user?.firstName ?? "",
    lastName: user?.lastName ?? "",
    country: user?.country ?? "",
    addressLine1: user?.addressLine1 ?? "",
    postCode: user?.postCode ?? "",
    intendedEmail: user?.email ?? "",
    intendedConfirmEmail: user?.email ?? "",
    taxId: user?.taxId ?? "",
    phoneNumber: user?.phoneNumber ?? "",
    isNeedInvoice: false,
    wantsRegister: !isAuthenticated,
  }

  if (!isAuthenticated && !useSavedCard) {
    validationObject = {
      ...validationObject,

      password: Yup.string().when("wantsRegister", {
        is: true,
        then: () =>
          password({
            formatMessage,
          }),
      }),
      confirmPassword: Yup.string().when("wantsRegister", {
        is: true,
        then: () =>
          Yup.string()
            .required(
              formatMessage({
                id: "forms::passwordRequiredError",
                defaultMessage: "A password is required.",
              })
            )
            .test(
              "password-match",
              formatMessage({
                id: "signup::password::notMatch",
                defaultMessage: "Both passwords need to be the same",
              }),
              function (value) {
                return value === this.resolve(Yup.ref("password"))
              }
            ),
      }),
      intendedEmail: emailValidation({ formatMessage }),
      intendedConfirmEmail: Yup.string()
        .when("intendedEmail", {
          is: (val) => (val && val.length > 0 ? true : false),
          then: () =>
            Yup.string().oneOf(
              [Yup.ref("intendedEmail")],
              formatMessage({
                id: "signup::email::notMatch",
                defaultMessage: "Both emails need to be the same",
              })
            ),
          otherwise: () => {},
        })
        .email(
          formatMessage({
            id: "forms::emailInvalidError",
            defaultMessage: "Enter a correct email",
          })
        )
        .required(
          formatMessage({
            id: "forms::emailRequiredError",
            defaultMessage: "Enter your email",
          })
        ),
    }

    initialValues = {
      ...initialValues,
      firstName: "",
      lastName: "",
      password: "",
      confirmPassword: "",
      intendedEmail: "",
      intendedConfirmEmail: "",
      taxId: "",
    }
  }

  if (
    (useSavedCard && allCards?.length) ||
    (isOfflinePacDiscount && isOfflineNoDonationNeeded)
  ) {
    delete validationObject.cardholderName
  }

  useEffect(() => {
    if (isAuthenticated && !clientSecret) {
      callCreateClientSecret({
        variables: { stripePaymentMethodType: selectedPaymentType },
      })
    }
    if (
      !allCards?.length &&
      !allSavedAccountsData?.called &&
      (user?.id || isAuthenticated)
    ) {
      callAllSavedAccounts()
    }
  }, [useSavedCard, user?.id, isAuthenticated])

  const validationSchema = Yup.object().shape(validationObject)

  /* const cardErrorList = {
    cardIncomplete: formatMessage({
      id: "forms::checkoutForm::cardIncomplete",
      defaultMessage: `Card information is incomplete!`,
    }),
  } */

  const [createNewDonation, { error: donationError, data: donationData }] =
    useMutation(CREATE_DONATION_MUTATION)

  const handleCardChange = (card, formErrors) => {
    setIsCardEmpty(card?.empty)
    const { error } = card
    setStripeErrorData(null)
    const stripeError = error?.code
      ? formatMessage({
          id: `forms::stripe::${error?.code}`,
          defaultMessage: `${error?.message}`,
        })
      : null
    const cardError =
      stripeError || !card?.complete
        ? formatMessage({
            id: "forms::checkoutForm::cardIncomplete",
            defaultMessage: `Card information is incomplete!`,
          })
        : ""

    if (cardError) {
      formErrors.card = cardError
    } else {
      delete formErrors.card
    }
    setCardError(cardError)
  }

  const handleCardFocus = () => {
    if (creditCardRef?.current) {
      creditCardRef?.current?.classList?.add("active")
    }
  }

  const handleLogin = ({ email, password }) => {
    setLoginLoading(true)
    signIn({
      email,
      password,
      silent: true,
    })
      .then((res) => {
        let incomingErrors = []
        if (res?.nonFieldErrors) {
          res.nonFieldErrors.map((item) => incomingErrors.push(item.message))
        }

        if (incomingErrors.length === 0) {
          setShowLogin(false)
        } else {
          // errorToaster(incomingErrors?.[0])
          errorToaster(
            formatMessage({
              id: "forms::error::invalidCredentials",
              defaultMessage: "Invalid credentials",
            })
          )
        }
      })
      .catch((err) => {
        //   setLoginErrors([err.message])
      })
      .finally(() => {
        setLoginLoading(false)
      })
  }

  const handleSubmit = async ({
    paymentAmount,
    cardholderName,
    agreeTerms,
    saveCard,
    intendedEmail,
    firstName,
    lastName,
    phoneNumber,
    taxId,
    note,
    isAnonymous,
    password,
    wantsRegister,
  }) => {
    if (showAmountProvision && !isQuantityAvailable) {
      errorToaster(
        formatMessage({
          defaultMessage: "There is no enough available quantity",
          id: "amount::provision::quantityWarning",
        })
      )
      return
    }
    setSubmittedOnce(true)
    if (isOfflineNoDonationNeeded) {
      onComplete({
        reconId,
        isOfflineNoDonationNeeded,
        paymentType: selectedPaymentType,
        confirmPaymentMethod,
      })
      return
    }

    if (!stripe || !elements) {
      return
    }

    let clientSecretToSubmit = clientSecret

    setProcessing(true)
    setStripeErrorData(null)
    if (creditCardRef?.current) {
      creditCardRef?.current?.classList?.add("active")
    }

    try {
      let tempStripeClientSecret
      let tempStripeId
      let tempStripeDonateByShoppingMerchant
      let tempStripePaymentMethodId
      let recurringData = {}
      let donationData = {}
      donationData.intendedEmail = intendedEmail
      donationData.firstName = firstName
      donationData.lastName = isCompany ? "" : lastName
      donationData.accountType = isCompany
        ? ACCOUNT_TYPE.BUSINESS
        : ACCOUNT_TYPE.PERSONAL
      donationData.phoneNumber = phoneNumber
      donationData.taxId = taxId
      let paymentType = selectedPaymentType

      if (selectedCard?.cardId) {
        paymentType =
          selectedCard?.brand === PAYMENT_TYPES.PAYPAL
            ? PAYMENT_TYPES.PAYPAL
            : PAYMENT_TYPES.CARD
      }
      const concludeDonation = async () => {
        if (
          !(tempStripeClientSecret || tempStripePaymentMethodId) ||
          !tempStripeId
        ) {
          throw new Error(
            formatMessage({
              id: "forms::error::stripeClientSecret",
              defaultMessage: "There was a problem during the transaction",
            })
          )
        }

        const card = elements.getElement("card")

        const paymentMethod = !card
          ? selectedCard?.cardId
          : country
          ? {
              card,
              billing_details: {
                address: {
                  country,
                },
                email,
                name: cardholderName,
              },
            }
          : {
              card,
              billing_details: {
                email,
                name: cardholderName,
              },
            }

        if (tempStripeClientSecret) {
          const confirmPayment = confirmPaymentMethod(paymentType)

          const returnUrl = new URL(location.href)
          returnUrl.searchParams.append("donationId", donationData?.id)

          const { paymentIntent, error } = await confirmPayment(
            tempStripeClientSecret,
            {
              payment_method: paymentMethod,
              return_url: returnUrl,
            }
          )

          if (error) {
            const stripeError = error?.code
              ? formatMessage({
                  id: `forms::stripe::${error?.code}`,
                  defaultMessage: `${error?.message}`,
                })
              : null

            if (useSavedCard)
              errorToaster(
                stripeError ||
                  formatMessage({
                    id: "forms::error::tryAgain",
                    defaultMessage: "Please try again later.",
                  })
              )
            else
              setStripeErrorData({
                errors: [
                  {
                    message: stripeError,
                  },
                ],
              })
          } else if (paymentIntent?.status === "succeeded") {
            if (!isAuthenticated) donationData.isDWOR = true
            onComplete({
              donationId: tempStripeId,
              merchantName: tempStripeDonateByShoppingMerchant?.name,
              donationData,
              recurringData,
              reconId,
              paymentType: selectedPaymentType,
              confirmPaymentMethod,
            })
            if (isRecurring && !useSavedCard) {
              success(cardSavedMessage)
            }
          }
        }
        if (tempStripePaymentMethodId) {
          onComplete({
            recurringData,
            reconId,
            paymentMethod,
            stripe,
            paymentType: selectedPaymentType,
            confirmPaymentMethod,
          })
          if (isRecurring && !useSavedCard) {
            success(cardSavedMessage)
          }
        }
      }
      if (isRecurring) {
        let stripePaymentMethodId = selectedCard?.cardId
        const interval =
          donationType === RECURRING_DONATION_TYPE.MONTHLY
            ? "MONTH"
            : donationType === RECURRING_DONATION_TYPE.ANNUALLY
            ? "YEAR"
            : ""
        const handleRecurringSubscription = async ({
          clientSecretToSubmit,
        }) => {
          if (!useSavedCard || !allCards?.length) {
            const cardElement = elements.getElement("card")
            if (!clientSecretToSubmit) {
              throw new Error(
                formatMessage({
                  id: "forms::error::stripeClientSecret",
                  defaultMessage: "There was a problem during the transaction",
                })
              )
            }

            const confirmSetup = confirmSetupMethod(paymentType)

            const returnUrl = new URL(location.href)
            returnUrl.searchParams.append("amount", paymentAmount)
            returnUrl.searchParams.append("interval", interval)
            returnUrl.searchParams.append("isAnonymous", isAnonymous)
            if (reconId) {
              returnUrl.searchParams.append("reconId", reconId)
            }
            returnUrl.searchParams.append(
              "subscriptionProduct",
              subscriptionProductsId
            )

            const result = await confirmSetup(clientSecretToSubmit, {
              payment_method: {
                card: cardElement,
                billing_details: {
                  name: cardholderName,
                  email,
                },
              },
              return_url: returnUrl,
            })
            if (result?.error?.code) throw new Error(result?.error?.message)
            stripePaymentMethodId = result?.setupIntent?.payment_method
          }
          const { data } = await createSubscription(
            subscriptionProductsId,
            paymentAmount.toString(),
            interval,
            stripePaymentMethodId,
            isAnonymous
          )

          recurringData = handleSubscriptionResult(data, recurringData)

          const { subscription, errors } = { ...data?.createSubscription }
          if (errors?.length) throw new Error(errors?.[0].messages[0])

          tempStripeId = subscription?.id
          tempStripePaymentMethodId = subscription?.stripePaymentMethodId
        }

        if (!isAuthenticated) {
          callCreateClientSecretWithRegisterMutation({
            variables: {
              stripePaymentMethodType: selectedPaymentType,
              email: intendedEmail,
              password,
              firstName,
              lastName,
            },
          }).then(async ({ data }) => {
            clientSecretToSubmit =
              data?.createClientSecret?.clientSecret || clientSecret
            setCredentials({
              token: data?.createClientSecret?.token,
              refreshToken: data?.createClientSecret?.refreshToken,
            })
            await setIsAuthenticated(true)

            await updateCommunicationLanguageWithBrowser()

            await callUserProfile(true)
            await handleRecurringSubscription({ clientSecretToSubmit })
            await concludeDonation()
          })
        } else {
          await handleRecurringSubscription({ clientSecretToSubmit })
          await concludeDonation()
        }
      } else {
        const newDonationInputValues = {
          amount: donationProvision?.expectedAmount ?? paymentAmount.toString(),
          campaign: campaignID,
          isAnonymous: isOfflineMerchantCampaign ? true : isAnonymous,
          isTermsAndPolicyAccepted: agreeTerms,
          redirectTo,
          donateByShoppingMerchant: merchantId,
          tier: selectedTier,
          stripeSavePaymentMethodForFuture: saveCard,
          stripePaymentMethodId: selectedCard?.cardId,
          intendedEmail: intendedEmail?.toLowerCase(),
          firstName,
          lastName: isCompany ? "" : lastName,
          phoneNumber,
          merchantPacReconciliation: reconId,
          stripePaymentMethodType: paymentType,
          taxId,
          note,
          donationProvision: donationProvision?.id,
        }
        if (referralCode) newDonationInputValues.referral = referralCode
        if (wantsRegister && password) {
          newDonationInputValues.password = password
          newDonationInputValues.accountType = isCompany
            ? ACCOUNT_TYPE.BUSINESS
            : ACCOUNT_TYPE.PERSONAL

          if (!isAuthenticated) donationData.isDWOR = true
        }

        const { data } = await createNewDonation({
          variables: {
            input: newDonationInputValues,
          },
        })

        const { donation, errors } = { ...data?.createDonation }

        if (errors?.length) throw new Error(errors?.[0].messages[0])
        donationData = donation
        donationData.firstName = firstName
        donationData.lastName = isCompany ? "" : lastName
        donationData.accountType = isCompany
          ? ACCOUNT_TYPE.BUSINESS
          : ACCOUNT_TYPE.PERSONAL
        donationData.phoneNumber = phoneNumber
        donationData.taxId = taxId
        if (wantsRegister) {
          if (!isAuthenticated) donationData.isDWOR = true
          setCredentials({
            token: donation?.token,
            refreshToken: donation?.refreshToken,
          })
          setIsAuthenticated(true)
          await updateCommunicationLanguageWithBrowser()
          await callUserProfile(true)
        }
        if (isBankTransfer) {
          navigate(
            `/campaigns/${campaign?.slug}/bank-transfer-details?id=${donation?.id}`
          )
          return
        }

        const { stripeClientSecret, id, donateByShoppingMerchant } = {
          ...donation,
        }

        tempStripeClientSecret = stripeClientSecret
        tempStripeId = id
        tempStripeDonateByShoppingMerchant = donateByShoppingMerchant
        donationData.intendedEmail = intendedEmail
        donationData.firstName = firstName
        donationData.lastName = isCompany ? "" : lastName
        donationData.accountType = isCompany
          ? ACCOUNT_TYPE.BUSINESS
          : ACCOUNT_TYPE.PERSONAL
        donationData.phoneNumber = phoneNumber
        donationData.taxId = taxId
        concludeDonation()
      }
    } catch (err) {
      errorToaster(err?.message, null, false)
    }
    setProcessing(false)
  }
  // End of handlesubmit

  let cardElementClassName = "full-width credit_card"
  if (processing === true) {
    cardElementClassName = "full-width credit_card apply-blur"
  }

  const handleSignUp = () => {
    navigate(
      `/signup?next=${
        window?.location?.pathname.replace(/\/(en|it|es)/g, "") +
        window?.location?.search
      }`
    )
  }
  const cardSavedMessage = formatMessage({
    id: "donation::recurring::cardSavedMessage",
    defaultMessage:
      "Your card is saved for recurring donation. You can check it on your profile.",
  })

  const el =
    typeof window !== "undefined" && document.getElementsByTagName("body")[0]
  if (el && el.style) el.style.paddingBottom = !isTablet ? "1rem" : 0

  const AnonymousInfoText = formatMessage({
    id: "donation::anonymousInfoText",
    defaultMessage:
      "Choosing to donate anonymously, you will appear as “Anonymous” to other donors. Organizers and responsible payment processors and others will receive your data as described in our Privacy Policy",
  })

  const pacsToEarn = (amount) =>
    (!isOfflinePacDiscount ||
      (isOfflinePacDiscount && Number(pacMultiplier) > 1)) &&
    !isOfflineNoDonationNeeded && (
      <>
        <p className="caption">
          <FormattedMessage
            id="campaign::donationForm::pacsYouwillEarn"
            defaultMessage="PACS you will earn"
          />
          {Number(pacMultiplier) > 1 && (
            <span className="caption pac-earn">
              {` X `}
              <Money value={pacMultiplier * quantity} currency={false} />
            </span>
          )}
        </p>
        <StyledPACWrapper className="pac">
          {/* <Logo className="pac-icon" width={33} height={40} fill="inherit" /> */}
          <FormattedMessage
            id="campaign::donationForm::pacsEuro"
            defaultMessage="{pacs} €/pacs"
            values={{
              pacs: <Money value={amount} currency={false} />,
            }}
          />
        </StyledPACWrapper>
      </>
    )

  if (isPayPalProcessing) {
    return <Spinner />
  }

  return (
    <StyledPageWrapper>
      {(processing === true || (hasReconId && detailLoading)) && (
        <div className="spinner">
          <Spinner condensed={true} />
        </div>
      )}
      <div className={processing === true ? "spinning-form" : ""}>
        <FormWrapper>
          <Formik
            enableReinitialize="true"
            initialValues={initialValues}
            validateOnMount
            validationSchema={validationSchema}
            onSubmit={handleSubmit}>
            {({
              values,
              touched,
              errors,
              handleChange,
              handleBlur,
              setFieldTouched,
              setFieldValue,
              setErrors,
            }) => (
              <Form>
                <OnSubmitValidationError />
                {isOfflinePacDiscount ? (
                  <PartialForOfflineDonation
                    userBalance={userBalance}
                    isLowerThenMinDonation={isLowerThenMinDonation}
                    pacReconDetail={pacReconDetail}
                    pacsLogoContent={pacsLogoContent}
                    isOfflineNoDonationNeeded={isOfflineNoDonationNeeded}
                    pacMultiplier={pacMultiplier}
                    amountData={amountData}
                  />
                ) : null}
                {isOfflinePacDiscount && isOfflineNoDonationNeeded ? (
                  <StyledFormRow className="free-donation">
                    <StyledLabel className="caption">
                      <FormattedMessage
                        id="campaign::donationForm::pacUsedAmount"
                        defaultMessage="PAC used amount"
                      />
                    </StyledLabel>
                    <StyledMoney>
                      {pacsLogoContent}
                      <Money
                        value={pacReconDetail?.totalPacsRequired || 0}
                        currency={false}
                      />
                    </StyledMoney>
                  </StyledFormRow>
                ) : (
                  <>
                    {showAmountProvision && values?.paymentAmount && (
                      <>
                        <StyledFormRow>
                          <StyledLabel className="caption">
                            <FormattedMessage
                              id="campaign::donationForm::quantity"
                              defaultMessage="Quantity"
                            />
                          </StyledLabel>
                          <AmountProvisioner
                            amount={values?.paymentAmount}
                            tier={
                              selectedTier ??
                              campaign?.tiers?.edges?.[0]?.node?.id
                            }
                            onError={() => {
                              setIsQuantityAvailable(false)
                            }}
                            onChange={(
                              val,
                              expected,
                              donationProvisionData
                            ) => {
                              setIsQuantityAvailable(true)
                              setQuantity(val)
                              setExpectedQuantity(expected)
                              setDonationProvision(donationProvisionData)
                            }}
                            expectedQuantityParam={tier?.maxTickets}
                          />
                        </StyledFormRow>
                      </>
                    )}
                    <StyledFormRow className="free-donation">
                      {isAmountFixed && amountData ? (
                        <>
                          <StyledLabel className="caption">
                            <FormattedMessage
                              id="campaign::donationForm::youWillBeCharged"
                              defaultMessage="You will be charged"
                            />
                          </StyledLabel>
                          {!isNaN(values?.paymentAmount) && (
                            <StyledMoney>
                              <Money
                                value={values.paymentAmount * quantity || "-"}
                              />
                            </StyledMoney>
                          )}
                        </>
                      ) : (
                        <>
                          <p className="caption">
                            <FormattedMessage
                              id="campaign::donationForm::howMuchDonate"
                              defaultMessage="How much you want to donate"
                            />
                          </p>
                          <TextInput
                            newDesign
                            className="currency-icon"
                            id="paymentAmount"
                            name="paymentAmount"
                            value={values.paymentAmount}
                            placeholder=""
                            error={errors.paymentAmount}
                            touched={touched.paymentAmount}
                            onBlur={() => setFieldTouched("paymentAmount")}
                            onValueChange={({ value }) => {
                              setFieldValue(
                                "paymentAmount",
                                value ? Number(value) : "",
                                true
                              )
                            }}
                            type="currency"
                            maxLength={8}
                          />
                        </>
                      )}
                      {(!isOfflinePacDiscount ||
                        (isOfflinePacDiscount && Number(pacMultiplier) > 1)) &&
                        !isOfflineNoDonationNeeded &&
                        isAuthenticated && (
                          <>
                            <p className="caption">
                              <FormattedMessage
                                id="campaign::donationForm::pacsYouwillEarn"
                                defaultMessage="PACS you will earn"
                              />
                              {Number(pacMultiplier) > 1 && (
                                <span className="caption pac-earn">
                                  {` X `}
                                  <Money
                                    value={pacMultiplier * quantity}
                                    currency={false}
                                  />
                                </span>
                              )}
                            </p>
                            <StyledPACWrapper>
                              <Logo
                                className="pac-icon"
                                width={33}
                                height={40}
                                fill="inherit"
                              />
                              <FormattedMessage
                                id="campaign::donationForm::pacs"
                                defaultMessage="{pacs} pacs"
                                values={{
                                  pacs: (
                                    <Money
                                      value={
                                        !isAuthenticated
                                          ? 0
                                          : pacMultiplier
                                          ? Number(
                                              values.paymentAmount * quantity ||
                                                0
                                            ) * Number(pacMultiplier || 0)
                                          : values.paymentAmount * quantity || 0
                                      }
                                      currency={false}
                                    />
                                  ),
                                }}
                              />
                            </StyledPACWrapper>
                          </>
                        )}
                    </StyledFormRow>
                    {!isAuthenticated && !showLogin ? (
                      <>
                        <StyledFlexBoxRow className="my32">
                          <StyledRegisterBox
                            active={values.wantsRegister}
                            onClick={(e) => {
                              setFieldValue("wantsRegister", true)
                            }}>
                            <p className="head">
                              {" "}
                              {formatMessage({
                                id: "service::checkoutForm::registerOption",
                                defaultMessage: "Register",
                              })}
                            </p>

                            {pacsToEarn(
                              pacMultiplier
                                ? Number(values.paymentAmount || 0) *
                                    Number(pacMultiplier || 0)
                                : values.paymentAmount || 0
                            )}
                          </StyledRegisterBox>
                          {isRecurring ? null : (
                            <StyledRegisterBox
                              active={!values.wantsRegister}
                              onClick={(e) => {
                                setFieldValue("wantsRegister", false)
                              }}>
                              <p className="head">
                                {formatMessage({
                                  id: "service::checkoutForm::notRegisterOption",
                                  defaultMessage: "Do not register",
                                })}
                              </p>

                              {pacsToEarn(
                                !isAuthenticated
                                  ? 0
                                  : pacMultiplier
                                  ? Number(
                                      values.paymentAmount * quantity || 0
                                    ) * Number(pacMultiplier || 0)
                                  : values.paymentAmount * quantity || 0
                              )}
                            </StyledRegisterBox>
                          )}
                        </StyledFlexBoxRow>
                        <StyledFlexBoxRow className="title">
                          <FormattedMessage
                            id="signUp::alreadyHaveAccount?"
                            defaultMessage="Already have an account?"
                          />
                          <StyledTitle
                            disabled={showLogin}
                            onClick={handleClickLoginLink}>
                            <FormattedMessage
                              id="service::checkoutForm::logIn"
                              defaultMessage="Log in"
                            />
                          </StyledTitle>
                        </StyledFlexBoxRow>
                      </>
                    ) : null}
                    {!isAuthenticated ? (
                      <>
                        {isRecurring ? (
                          <>
                            <StyledSectionTitle>
                              <FormattedMessage
                                id="service::checkoutForm::registerOrLogin"
                                defaultMessage="Register or log in to start a recurring donation."
                              />
                            </StyledSectionTitle>
                            <StyledSectionTitle className="mb24">
                              <FormattedMessage
                                id="service::checkoutForm::stopRecurring"
                                defaultMessage="You can stop recurring donation anytime in your settings"
                              />
                            </StyledSectionTitle>
                          </>
                        ) : null}
                        {showLogin ? (
                          <StyledTitle
                            onClick={() => {
                              setShowLogin(false)
                            }}>
                            {isRecurring ? (
                              <FormattedMessage
                                id="service::checkoutForm::registerOption"
                                defaultMessage="Register"
                              />
                            ) : (
                              <FormattedMessage
                                id="service::checkoutForm::continueWOSignin"
                                defaultMessage="Continue without signing in"
                              />
                            )}
                          </StyledTitle>
                        ) : null}
                        {showLogin || (!showLogin && values.wantsRegister) ? (
                          <StyledMobileGrayBox>
                            <StyledSectionBox className="mb32">
                              {!showLogin && values.wantsRegister ? (
                                <>
                                  <SocialLoginComponent medium silent />
                                  <div className="mt24">
                                    {values.wantsRegister ? (
                                      <FormattedMessage
                                        id="service::checkoutForm::orCreateAnAccount"
                                        defaultMessage="Or create an account with an email and password"
                                      />
                                    ) : (
                                      <FormattedMessage
                                        id="service::checkoutForm::enterAnEmail"
                                        defaultMessage="Enter an email to which we will send your receipt"
                                      />
                                    )}
                                  </div>
                                </>
                              ) : (
                                <>
                                  {alreadyHaveAccount ? (
                                    <StyledWarningTitle>
                                      <FormattedMessage
                                        id="service::checkoutForm::alreadyHaveAccount"
                                        defaultMessage="You already have an account"
                                        tagName={"p"}
                                      />
                                      <FormattedMessage
                                        id="service::checkoutForm::loginToUsePacs"
                                        defaultMessage="Log in to use your PACs"
                                        tagName={"p"}
                                      />
                                    </StyledWarningTitle>
                                  ) : null}
                                  <SocialLoginComponent medium silent />
                                  {!alreadyHaveAccount ? (
                                    <div className="center mt24">
                                      <FormattedMessage
                                        id="service::checkoutForm::orEnterCredentials"
                                        defaultMessage="Or enter your credentials"
                                      />
                                    </div>
                                  ) : null}
                                </>
                              )}
                              <TextInput
                                newDesign
                                className="mt24"
                                data-testid="input-email"
                                id="intendedEmail"
                                name="intendedEmail"
                                type="email"
                                onChange={(e) => {
                                  const lowerCaseEmail =
                                    e.target.value.toLowerCase()
                                  handleChange({
                                    target: {
                                      name: e.target.name,
                                      value: lowerCaseEmail,
                                    },
                                  })
                                }}
                                onBlur={(e) => {
                                  handleBlur(e)
                                  if (
                                    values.intendedEmail ===
                                      values.intendedConfirmEmail &&
                                    !errors.intendedEmail &&
                                    !errors.intendedConfirmEmail
                                  ) {
                                    checkUserExist({
                                      variables: {
                                        email: values.intendedConfirmEmail,
                                      },
                                    })
                                  }
                                }}
                                value={values?.intendedEmail}
                                label={
                                  <StyledEmailLabel>
                                    {formatMessage({
                                      id: "signUp::email",
                                      defaultMessage: "Email",
                                    })}
                                    {REQUIRED_FIELD_SYMBOL}
                                    <div
                                      data-tooltip-id="emailTooltip"
                                      data-tooltip-content={
                                        emailInformationText
                                      }
                                      data-tooltip-place="bottom">
                                      <FontAwesomeIcon
                                        className="icon info-icon"
                                        icon={faCircleInfo}
                                      />
                                    </div>
                                    <Tooltip
                                      id="emailTooltip"
                                      className="tooltip"
                                    />
                                  </StyledEmailLabel>
                                }
                                placeholder={formatMessage({
                                  id: "signUp::emailPlaceholder",
                                  defaultMessage: "Your personal email address",
                                })}
                                error={errors.intendedEmail}
                                touched={touched.intendedEmail}
                              />

                              {showLogin ? null : (
                                <TextInput
                                  newDesign
                                  className="mt24"
                                  data-testid="input-confirmEmail"
                                  id="intendedConfirmEmail"
                                  name="intendedConfirmEmail"
                                  type="email"
                                  onChange={(e) => {
                                    const lowerCaseEmail =
                                      e.target.value.toLowerCase()
                                    handleChange({
                                      target: {
                                        name: e.target.name,
                                        value: lowerCaseEmail,
                                      },
                                    })
                                  }}
                                  onBlur={(e) => {
                                    handleBlur(e)
                                    if (
                                      values.intendedEmail ===
                                        values.intendedConfirmEmail &&
                                      !errors.intendedEmail &&
                                      !errors.intendedConfirmEmail
                                    ) {
                                      checkUserExist({
                                        variables: {
                                          email: values.intendedConfirmEmail,
                                        },
                                      })
                                    }
                                  }}
                                  value={values?.intendedConfirmEmail}
                                  label={`${formatMessage({
                                    id: "signUp::confirmEmail",
                                    defaultMessage: "Verify Email",
                                  })}${REQUIRED_FIELD_SYMBOL}`}
                                  placeholder={formatMessage({
                                    id: "signUp::emailPlaceholder",
                                    defaultMessage:
                                      "Your personal email address",
                                  })}
                                  onPaste={(e) => {
                                    e.preventDefault()
                                    return false
                                  }}
                                  error={errors.intendedConfirmEmail}
                                  touched={touched.intendedConfirmEmail}
                                />
                              )}

                              {showLogin || values.wantsRegister ? (
                                <TextInput
                                  newDesign
                                  className="mt24"
                                  data-testid="input-password"
                                  id="password"
                                  name="password"
                                  type={"password"}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values?.password}
                                  label={`${formatMessage({
                                    id: "signUp::password",
                                    defaultMessage: "Password",
                                  })}${REQUIRED_FIELD_SYMBOL}`}
                                  placeholder={formatMessage({
                                    id: "signUp::password",
                                    defaultMessage: "Password",
                                  })}
                                  error={errors.password}
                                  touched={touched.password}
                                  iconRight={0.9375}
                                />
                              ) : null}
                              {!showLogin && values.wantsRegister ? (
                                <TextInput
                                  newDesign
                                  className="mt24"
                                  data-testid="input-confirmPassword"
                                  id="confirmPassword"
                                  name="confirmPassword"
                                  type={"password"}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values?.confirmPassword}
                                  label={`${formatMessage({
                                    id: "signUp::confirmPassword",
                                    defaultMessage: "Confirm Password",
                                  })}${REQUIRED_FIELD_SYMBOL}`}
                                  placeholder={formatMessage({
                                    id: "signUp::confirmPassword",
                                    defaultMessage: "Confirm Password",
                                  })}
                                  onPaste={(e) => {
                                    e.preventDefault()
                                    return false
                                  }}
                                  error={errors.confirmPassword}
                                  touched={touched.confirmPassword}
                                  iconRight={0.9375}
                                />
                              ) : null}
                              {showLogin ? (
                                <StyledLoginActions
                                  className={
                                    !isTablet ? "sticky-bottom-bar" : ""
                                  }>
                                  <StyledBlueText
                                    className="mb24"
                                    to="/password-recovery/">
                                    <FormattedMessage
                                      id="service::checkoutForm::forgotPassword"
                                      defaultMessage="Forgot password?"
                                    />
                                  </StyledBlueText>
                                  <Button
                                    color="blue"
                                    type="submit"
                                    onClick={() => {
                                      handleLogin({
                                        email: values.intendedEmail,
                                        password: values.password,
                                      })
                                    }}
                                    disabled={
                                      !values.intendedEmail ||
                                      !values.password ||
                                      loginLoading ||
                                      loading
                                    }>
                                    <FormattedMessage
                                      id="service::checkoutForm::logIn"
                                      defaultMessage="log in"
                                    />
                                  </Button>
                                </StyledLoginActions>
                              ) : null}
                            </StyledSectionBox>
                          </StyledMobileGrayBox>
                        ) : null}
                      </>
                    ) : null}
                  </>
                )}
                {!isAuthenticated && showLogin ? null : (
                  <>
                    {isOfflinePacDiscount &&
                    isOfflineNoDonationNeeded ? null : (
                      <>
                        {!isAuthenticated ? (
                          <>
                            <StyledSectionTitle className="mb16">
                              <FormattedMessage
                                id="service::checkoutForm::yourDetails"
                                defaultMessage="Your details"
                              />
                            </StyledSectionTitle>
                            <StyledSectionBox className="mb32 pb0">
                              <StyledFormRow className="title mt32">
                                <FormattedMessage
                                  id="campaign::donationForm::companyOrIndividual"
                                  defaultMessage="Are you an organization or an individual?"
                                />
                              </StyledFormRow>
                              <StyledFlexFormRow>
                                <RadioSelect
                                  items={userTypeShort(formatMessage)}
                                  defaultValue={
                                    isCompany ? "company" : "individual"
                                  }
                                  onChange={(e) => {
                                    setIsCompany(e.target.value === "company")
                                    setErrors({})
                                  }}
                                />
                              </StyledFlexFormRow>
                              <SingleDonation
                                isPhoneRequired={
                                  campaign?.campaignType === "EV"
                                }
                                isCompany={isCompany}
                                setIsCompany={setIsCompany}
                                isLoggedIn={isAuthenticated}
                              />
                            </StyledSectionBox>
                          </>
                        ) : null}

                        {!isAuthenticated ||
                        !useSavedCard ||
                        allCards?.length === 0 ? (
                          <>
                            <StyledFormRow>
                              <StyledSectionTitle className="mt24">
                                <FormattedMessage
                                  id="campaign::donationForm::paymentMethodTitle"
                                  defaultMessage="Payment Method"
                                />
                              </StyledSectionTitle>
                              <Spacer top={1} />
                              <PaymentMethods
                                hideBankTransfer={true || isRecurring}
                                onSelect={async (val) => {
                                  setSelectedPaymentType(val)

                                  await callCreateClientSecret({
                                    variables: { stripePaymentMethodType: val },
                                  })
                                }}
                                amount={values?.paymentAmount}
                              />
                            </StyledFormRow>
                            {!isPayPal && !isBankTransfer && (
                              <>
                                <StyledFormRow>
                                  <p className="caption">
                                    <FormattedMessage
                                      id="campaign::donationForm::cardholderName"
                                      defaultMessage="Cardholder Name"
                                    />
                                    {REQUIRED_FIELD_SYMBOL}
                                  </p>
                                  <TextInput
                                    newDesign
                                    className={cardElementClassName}
                                    id="cardholderName"
                                    name="cardholderName"
                                    value={values.cardholderName}
                                    placeholder={formatMessage({
                                      id: "campaign::donationForm::cardholderNamePlaceHolder",
                                      defaultMessage: "Name on the card",
                                    })}
                                    error={errors.cardholderName}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    touched={touched.cardholderName}
                                  />
                                </StyledFormRow>
                                <StyledFormRow
                                  className={`${cardElementClassName} 
                              ${submittedOnce && isCardEmpty ? "error" : ""}`}
                                  cardError={!!cardError || !!stripeErrorData}>
                                  <p className="caption">
                                    <FormattedMessage
                                      id="campaign::donationForm::cardDetails"
                                      defaultMessage="Card Details"
                                    />
                                    {REQUIRED_FIELD_SYMBOL}
                                  </p>
                                  <CardElement
                                    onFocus={handleCardFocus}
                                    onChange={(card) =>
                                      handleCardChange(card, errors)
                                    }
                                    options={{
                                      disabled:
                                        !values.paymentAmount || processing,
                                      style: cardElementStyles,
                                    }}
                                  />
                                  <p
                                    ref={creditCardRefError}
                                    className="input-feedback">
                                    {cardError}
                                  </p>
                                  {!cardError && stripeErrorData && (
                                    <p>
                                      <FormErrors errors={stripeErrorData} />
                                    </p>
                                  )}
                                  {submittedOnce && isCardEmpty && (
                                    <StyledCardErrorWrapper className="input-feedback error">
                                      <FormattedMessage
                                        id="forms::requiredField"
                                        defaultMessage="This field is required"
                                      />
                                    </StyledCardErrorWrapper>
                                  )}
                                </StyledFormRow>
                              </>
                            )}
                            {campaign?.enableDonationNote && (
                              <StyledFormRow>
                                <p className="caption">
                                  <FormattedMessage
                                    id="campaign::donationForm::NGOMessage"
                                    defaultMessage="Message"
                                  />
                                </p>
                                <TextInput
                                  newDesign
                                  type="textarea"
                                  className={cardElementClassName}
                                  id="note"
                                  name="note"
                                  value={values.note}
                                  maxLength={120}
                                  placeholder={formatMessage({
                                    id: "campaign::donationForm::NGOMessagePlaceHolder",
                                    defaultMessage: "Leave your comment here",
                                  })}
                                  error={errors.note}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  touched={touched.note}
                                />
                              </StyledFormRow>
                            )}

                            {allCards?.length > 0 && (
                              <StyledFormRow>
                                <StyledUseAnotherCardButton
                                  onClick={() => {
                                    setUseSavedCard(true)
                                  }}>
                                  <FormattedMessage
                                    id="campaign::donationForm::useSavedCard"
                                    defaultMessage="Use saved card"
                                  />
                                </StyledUseAnotherCardButton>
                              </StyledFormRow>
                            )}

                            {isRecurring ? (
                              <>
                                <Styled3DInfoText>
                                  <FormattedMessage
                                    id="campaign::donationForm::cardInfoText"
                                    defaultMessage="Your card details will be saved for processing the
                            next recurring donation."
                                    tagName="span"
                                  />
                                  <FormattedMessage
                                    id="campaign::donationForm::cardInfoText2"
                                    defaultMessage="Depending on your card processor you may need to
                            authenticate your card twice."
                                    tagName="span"
                                  />
                                </Styled3DInfoText>
                              </>
                            ) : (
                              isAuthenticated &&
                              !isBankTransfer && (
                                <StyledFormRow>
                                  <StyledAgreeTerms>
                                    <CheckboxContainer>
                                      <label htmlFor="saveCard">
                                        <input
                                          tabIndex="0"
                                          data-testid="check-saveCard"
                                          id="saveCard"
                                          name="saveCard"
                                          type="checkbox"
                                          checked={values.saveCard}
                                          onChange={handleChange}
                                          onClick={() =>
                                            setFieldTouched("saveCard", true)
                                          }
                                        />
                                        <span className="checkmark"></span>
                                        <FormattedMessage
                                          id="campaign::donationForm::saveCard"
                                          defaultMessage="Save Card"
                                        />
                                      </label>
                                    </CheckboxContainer>
                                  </StyledAgreeTerms>
                                </StyledFormRow>
                              )
                            )}
                          </>
                        ) : (
                          <>
                            {allCards?.length && (
                              <StyledFormRow>
                                <p className="caption">
                                  <FormattedMessage
                                    id="campaign::donationForm::paymentMethodTitle"
                                    defaultMessage="Payment Method"
                                  />
                                </p>
                                <CreditCardSelector
                                  cards={allCards}
                                  onChange={(card) => {
                                    setSelectedCard(card)
                                    const cardType =
                                      card?.brand?.toLowerCase() ===
                                      PAYMENT_TYPES.PAYPAL
                                        ? PAYMENT_TYPES.PAYPAL
                                        : PAYMENT_TYPES.CARD
                                    callCreateClientSecret({
                                      variables: {
                                        stripePaymentMethodType: cardType,
                                      },
                                    })
                                  }}
                                  amount={values?.paymentAmount}
                                />
                                {campaign?.enableDonationNote && (
                                  <StyledFormRow>
                                    <p className="caption">
                                      <FormattedMessage
                                        id="campaign::donationForm::NGOMessage"
                                        defaultMessage="Message"
                                      />
                                    </p>
                                    <TextInput
                                      newDesign
                                      type="textarea"
                                      className={cardElementClassName}
                                      id="note"
                                      name="note"
                                      value={values.note}
                                      maxLength={120}
                                      placeholder={formatMessage({
                                        id: "campaign::donationForm::NGOMessagePlaceHolder",
                                        defaultMessage:
                                          "Leave your comment here",
                                      })}
                                      error={errors.note}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      touched={touched.note}
                                    />
                                  </StyledFormRow>
                                )}

                                <StyledUseAnotherCardButton
                                  onClick={() => {
                                    setUseSavedCard(false)
                                    setSelectedCard(null)
                                  }}>
                                  <FormattedMessage
                                    id="campaign::donationForm::useAnotherCard"
                                    defaultMessage="Use another card"
                                  />
                                </StyledUseAnotherCardButton>
                              </StyledFormRow>
                            )}
                          </>
                        )}
                      </>
                    )}

                    {isOfflineMerchantCampaign ? null : (
                      <StyledFormRow>
                        <CheckboxContainer>
                          <StyledCheckboxLine>
                            <label htmlFor="isAnonymous">
                              <input
                                tabIndex="0"
                                data-testid="check-isAnonymous"
                                id="isAnonymous"
                                name="isAnonymous"
                                type="checkbox"
                                checked={values.isAnonymous}
                                onChange={handleChange}
                                onClick={() => {
                                  setFieldTouched("isAnonymous", true)
                                }}
                              />
                              <span className="checkmark"></span>
                              {formatMessage({
                                id: "campaign::donationForm::donateAnonymously",
                                defaultMessage: "Donate anonymously",
                              })}
                            </label>
                            <div
                              data-tooltip-id="customTooltip"
                              data-tooltip-content={AnonymousInfoText}
                              data-tooltip-place="bottom">
                              <FontAwesomeIcon
                                className="icon info-icon"
                                icon={faCircleInfo}
                              />
                            </div>
                            <Tooltip id="customTooltip" className="tooltip" />
                          </StyledCheckboxLine>
                          <FieldError name="isAnonymous" />
                        </CheckboxContainer>
                      </StyledFormRow>
                    )}

                    <StyledFormRow>
                      <StyledAgreeTerms>
                        <TermsAndConditions
                          name="agreeTerms"
                          checked={values.agreeTerms}
                          errors={errors}
                          touched={touched}
                          onChange={handleChange}
                          onClick={() => setFieldTouched("agreeTerms", true)}
                          page="donation"
                        />
                      </StyledAgreeTerms>
                    </StyledFormRow>
                    {(subscriptionError ||
                      donationError ||
                      subscriptionData?.errors ||
                      donationData?.errors) && (
                      <StyledErrorMessage className="error">
                        <FormErrors
                          error={
                            isRecurring ? subscriptionError : donationError
                          }
                          errors={
                            isRecurring
                              ? subscriptionData?.errors
                              : donationData?.errors
                          }
                        />
                      </StyledErrorMessage>
                    )}
                    <StyledButtonContainer
                      className={!isTablet ? "sticky-bottom-bar" : ""}>
                      <Button
                        color="blue"
                        type="submit"
                        onClick={() => {
                          setSubmittedOnce(true)
                        }}
                        label={
                          process.env.IS_DISABLE_DONATION_PAYMENT === "true"
                            ? "Coming Soon"
                            : "Continue"
                        }
                        disabled={
                          !values.agreeTerms ||
                          !values.paymentAmount ||
                          processing ||
                          process.env.IS_DISABLE_DONATION_PAYMENT === "true" ||
                          cardError?.length
                        }>
                        {process.env.IS_DISABLE_DONATION_PAYMENT === "true" ? (
                          <FormattedMessage
                            id="button::text::comingSoon"
                            defaultMessage="Coming Soon"
                          />
                        ) : (
                          <FormattedMessage
                            id="ui::continue"
                            defaultMessage="Continue"
                          />
                        )}
                      </Button>
                    </StyledButtonContainer>
                  </>
                )}
              </Form>
            )}
          </Formik>
        </FormWrapper>
      </div>
    </StyledPageWrapper>
  )
}

DonationForm.propTypes = {
  amount: PropTypes.string,
  campaignID: PropTypes.string.isRequired,
  redirectTo: PropTypes.string,
  merchantId: PropTypes.string,
  onComplete: PropTypes.func,
  isAmountFixed: PropTypes.bool,
  amountMin: PropTypes.string,
  amountMax: PropTypes.string,
  selectedTier: PropTypes.string,
  pacMultiplier: PropTypes.string,
}

export default DonationForm
