import React, { useState, useEffect } from "react"
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
} from "react-share"
import { useIntl, FormattedMessage } from "gatsby-plugin-intl"
import { Tooltip } from "react-tooltip"
import { getAllScreenTypes } from "@tmu/utils/mediaQueries"
import { useToast } from "@tmu/hooks"
import { CustomModal, TextInput, QRModal } from "@tmu/components/common"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faShareAlt as faShare } from "@fortawesome/pro-regular-svg-icons/faShareAlt"
import { faFacebookSquare } from "@fortawesome/free-brands-svg-icons/faFacebookSquare"
import { faXTwitter } from "@fortawesome/free-brands-svg-icons/faXTwitter"
import { faLinkedinIn } from "@fortawesome/free-brands-svg-icons/faLinkedinIn"
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons/faWhatsapp"
import { faClone } from "@fortawesome/free-solid-svg-icons/faClone"
import theme from "@tmu/global/theme"
import { StyledSocialShare } from "../../partners/PartnerDetail/index.styles"
import {
  StyledShareModalContainer,
  StyledSocialMethodIcon,
  StyledSocialMethod,
  StyledSocialMethodTitle,
  StyledShareModalInputContainer,
} from "./index.styles"

/* 
import {
  EmailIcon,
  FacebookIcon,
  FacebookMessengerIcon,
   InstapaperIcon,
   LinkedinIcon,
   PinterestIcon,
   TelegramIcon,
   TwitterIcon,
   WhatsappIcon,
   XIcon,
} from "react-share";
*/
const ShareComponent = ({
  campaignId,
  offerId,
  url,
  description,
  slug,
  isNewCampaign,
  name,
  isMerchantCampaign,
  discount,
  storeLogo,
  partnerLogo,
  position = "left",
  iconColor = theme.colors.carrot,
}) => {
  const { formatMessage, locale } = useIntl()
  const { success } = useToast()
  const { isTablet } = getAllScreenTypes()
  const _url = url || location.href
  const [shareUrl, setShareUrl] = useState(
    url || _url?.charAt(_url.length - 1) === "/" ? _url : `${_url}/`
  )
  const [isModalOpen, setIsModalOpen] = useState(false)

  const copiedMessage = formatMessage({
    id: "ui::copiedToClipboard",
    defaultMessage: "Copied to clipboard!",
  })

  useEffect(() => {
    if (campaignId || offerId) {
      const fetchShareUrl = async () => {
        try {
          const customDefaultHeaders = {
            "Content-Type": "application/json",
          }

          // Add custom headers if environment variables are present
          if (process.env.CF_ACCESS_CLIENT_ID) {
            customDefaultHeaders["CF-Access-Client-ID"] =
              process.env.CF_ACCESS_CLIENT_ID
          }

          if (process.env.CF_ACCESS_CLIENT_SECRET) {
            customDefaultHeaders["CF-Access-Client-Secret"] =
              process.env.CF_ACCESS_CLIENT_SECRET
          }

          const response = await fetch(
            process.env.SHARE_LINK +
              `/${campaignId ? "c" : "o"}/${locale}/share/`,
            {
              method: "POST",
              headers: customDefaultHeaders,
              body: campaignId
                ? JSON.stringify({ campaign_id: campaignId })
                : JSON.stringify({ offer_id: offerId }),
            }
          )

          const result = await response.json()
          if (result?.share_url) {
            setShareUrl(result.share_url)
          }
        } catch (error) {
          console.error("Failed to fetch share URL:", error)
        }
      }

      fetchShareUrl()
    }
  }, [campaignId, offerId])

  const handleShare = () => {
    try {
      if (isTablet) {
        setIsModalOpen(true)
      } else if (navigator?.share) {
        navigator.share({
          title: name,
          url: shareUrl || "https://trustmeup.com",
          text: name || "TrustMeUp",
        })
      }
    } catch (err) {
      console.error("Error sharing:", err)
    }
  }

  const handleCopyClick = () => {
    const input = document.createElement("input")
    input.value = shareUrl || url
    document.body.appendChild(input)
    input.select()
    document.execCommand("copy")
    document.body.removeChild(input)
    success(copiedMessage)
  }

  return (
    <>
      <StyledSocialShare>
        <StyledShareModalContainer position="right">
          {!isTablet && (
            <QRModal
              slug={slug}
              name={name}
              isMerchantCampaign={isMerchantCampaign}
              discount={discount}
              storeLogo={storeLogo}
              partnerLogo={partnerLogo}
            />
          )}
          <FontAwesomeIcon
            icon={faShare}
            onClick={handleShare}
            color={iconColor}
          />
        </StyledShareModalContainer>
      </StyledSocialShare>
      {isModalOpen && isTablet ? (
        <CustomModal
          isModalOpen={isModalOpen && isTablet}
          header={formatMessage({
            id: "campaign::detail::shareCampaign",
            defaultMessage: "Share",
          })}
          children={
            <>
              <StyledShareModalContainer position={position}>
                <StyledSocialMethodIcon>
                  <StyledSocialMethod currentColor={theme.colors.blue}>
                    <QRModal
                      slug={slug}
                      name={name}
                      isMerchantCampaign={isMerchantCampaign}
                      discount={discount}
                      storeLogo={storeLogo}
                      partnerLogo={partnerLogo}
                    />
                  </StyledSocialMethod>
                  <StyledSocialMethodTitle>
                    <FormattedMessage
                      id="campaign::detail::qrCode"
                      defaultMessage="QR code"
                    />
                  </StyledSocialMethodTitle>
                </StyledSocialMethodIcon>
                <>
                  <StyledSocialMethodIcon>
                    <StyledSocialMethod
                      currentColor={theme.colors.navyBlueFacebook}>
                      <FacebookShareButton url={shareUrl || url}>
                        <FontAwesomeIcon icon={faFacebookSquare} />
                      </FacebookShareButton>
                    </StyledSocialMethod>
                    <StyledSocialMethodTitle>Facebook</StyledSocialMethodTitle>
                  </StyledSocialMethodIcon>
                  <StyledSocialMethodIcon>
                    <StyledSocialMethod
                      currentColor={theme.colors.navyBlueLinkedin}>
                      <LinkedinShareButton
                        summary={description}
                        url={shareUrl || url}
                        windowWidth={1000}
                        windowHeight={1000}>
                        <FontAwesomeIcon icon={faLinkedinIn} />
                      </LinkedinShareButton>
                    </StyledSocialMethod>
                    <StyledSocialMethodTitle>LinkedIn</StyledSocialMethodTitle>
                  </StyledSocialMethodIcon>
                  <StyledSocialMethodIcon>
                    <StyledSocialMethod className="xtwitter">
                      <TwitterShareButton
                        url={shareUrl || url}
                        hashtags={["TMU", "TrustMeUp", slug]}>
                        <FontAwesomeIcon icon={faXTwitter} />
                      </TwitterShareButton>
                    </StyledSocialMethod>
                    <StyledSocialMethodTitle>Twitter</StyledSocialMethodTitle>
                  </StyledSocialMethodIcon>
                  <StyledSocialMethodIcon>
                    <StyledSocialMethod className="whatsapp">
                      <WhatsappShareButton
                        url={shareUrl || url}
                        hashtags={["TMU", "TrustMeUp", slug]}>
                        <FontAwesomeIcon icon={faWhatsapp} />
                      </WhatsappShareButton>
                    </StyledSocialMethod>
                    <StyledSocialMethodTitle>Whatsapp</StyledSocialMethodTitle>
                  </StyledSocialMethodIcon>
                </>
              </StyledShareModalContainer>
              <StyledShareModalInputContainer>
                <TextInput
                  newDesign
                  value={shareUrl || url}
                  readOnly
                  inputClassName="fade-effect"
                  inputIcon={
                    <FontAwesomeIcon
                      icon={faClone}
                      className="input-icon"
                      color={theme.colors.blue}
                      onClick={handleCopyClick}
                    />
                  }
                  iconRight={0.9375}
                />
              </StyledShareModalInputContainer>
            </>
          }
          cancelButtonAction={() => {
            setIsModalOpen(false)
          }}
          isCloseIcon={true}
          isMobile={false}
          isFull={false}
          closeIconClassName="close-icon"
        />
      ) : null}
      <Tooltip
        arrowColor="transparent"
        effect="solid"
        place="bottom"
        type="info"
        textColor="black"
        backgroundColor="white"
        id="copyButton"
        className="copyButton"
        delayShow={500}
      />
    </>
  )
}

export default ShareComponent
