import React, { useCallback, useEffect, useState, useContext } from "react"
import PropTypes from "prop-types"
import { useIntl, FormattedMessage, navigate } from "gatsby-plugin-intl"
import { useQuery } from "@apollo/client"
import { getAllScreenTypes } from "@tmu/utils/mediaQueries"
import { /* useCountries, */ useLogoImage } from "@tmu/hooks"
import { faTimes } from "@fortawesome/pro-light-svg-icons/faTimes"
import { faPhone } from "@fortawesome/free-solid-svg-icons"
import { faMapMarkerAlt } from "@fortawesome/pro-solid-svg-icons/faMapMarkerAlt"
import { faChevronDown } from "@fortawesome/free-solid-svg-icons/faChevronDown"
import { faChevronUp } from "@fortawesome/free-solid-svg-icons/faChevronUp"
import { faShareAlt as faShare } from "@fortawesome/pro-regular-svg-icons/faShareAlt"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  Spinner,
  MediaSlider,
  RadioSelect,
  Button,
  OfferStats,
  ShareComponent,
  DetailHeader,
  UseBefore,
} from "@tmu/components/common"
import ServiceListing from "@tmu/components/services/ServiceListing"
import ContentNotFound from "@tmu/components/ContentNotFound"
import SEO from "@tmu/components/seo"
import {
  StyledProductVariationOptions,
  StyledWrapper,
  StyledContent,
  StyledPageWrapper,
  StyledCongratsPopup,
  StyledCongratsMobile,
  StyledContactTitle,
  StyledPhoneBox,
  StyledLocationBox,
  StyledDetailHeader,
  StyledStoreLogoContainer,
  StyledStoreLogo,
  StyledStoreName,
  StyledMapMainContainer,
  StyledMapContainer,
  StyledMapGetDirections,
  StyledMapHideShow,
  StyledHeaderAndShare,
} from "./index.styles"
import { OFFER_DETAIL_QUERY } from "@tmu/apollo/storefront/queries/offer"
import {
  StyledSectionTitle,
  StyledPartnerDescription as StyledDescription,
  StyledSupportButton,
} from "@tmu/components/partners/PartnerDetail/index.styles"
import {
  DetailPageContainer,
  DetailPageMobileContainer,
  MainPart,
  SidePart,
  Spacer,
} from "@tmu/global/page-addons/detail-page.styles"
import theme from "@tmu/global/theme"
import { FOOTER_TYPE } from "@tmu/apollo/constants"
import { getValueForLocale } from "@tmu/utils/string"
import { getFormatPhoneNumber } from "@tmu/utils/formatPhoneNumber"
import {
  generateGoogleMapsLinkAndOpen,
  generateGoogleMapsEmbedLink,
} from "@tmu/utils/googleMaps"
import { useFooterType } from "@tmu/hooks"
import { HomePageContext } from "@tmu/context/homePageContext"
import queryString from "query-string"
import {
  StyledExpiryDate,
  StyledUseBefore,
} from "../../common/UseBefore/index.styles"

const ServiceDetail = ({ slug = "", location }) => {
  const { pathname, search } = location
  const isNewService = queryString.parse(search)?.new === "true"
  const referralCode = queryString.parse(search)?.referral

  const { locale, defaultLocale, formatMessage } = useIntl()
  const { setIntercomStatus } = useContext(HomePageContext)
  const [productVariationRadioOptions, setProductVariationRadioOptions] =
    useState([])
  const [selectedProductVariation, setSelectedProductVariation] = useState({})
  const [productVariationSelectValue, setProductVariationSelectValue] =
    useState("1")
  // const { countryOptions } = useCountries()
  const [isMapVisible, setIsMapVisible] = useState(false)

  const { loading, data } = useQuery(OFFER_DETAIL_QUERY({ locale }), {
    variables: { slug },
    fetchPolicy: "network-only",
    onCompleted: () => {
      setTimeout(function () {
        if (typeof window !== "undefined") {
          window.prerenderReady = true
        }
      }, 500)
    },
  })

  const logoImage = useLogoImage()

  const { isTablet, isDesktop, isWide } = getAllScreenTypes()

  const productVariationOptions = data?.offer?.productVariation?.edges || []

  useEffect(() => {
    setIntercomStatus({ hide: true })
  }, [])

  useFooterType({
    footerType: !isTablet ? FOOTER_TYPE.HIDE : FOOTER_TYPE.TIGHT,
  })

  useEffect(() => {
    const radioOptions = productVariationOptions.map((item) => {
      const labelText = getValueForLocale(
        item?.node,
        "name",
        locale,
        defaultLocale
      )

      const descriptionText = getValueForLocale(
        item?.node,
        "description",
        locale,
        defaultLocale
      )

      return {
        id: item?.node?.id,
        pacDiscount: item?.node?.pacDiscount,
        isUnlimitedTickets: item?.node?.isUnlimitedTickets,
        isSoldOut: item?.node?.isSoldOut,
        label: labelText,
        price: item?.node?.price,
        description: descriptionText,
      }
    })

    if (!radioOptions.length) return
    radioOptions.sort((a, b) => a?.price - b?.price)

    const selectedItem = radioOptions.reduce((prev, current) => {
      var prevPrice = parseFloat(prev.price)
      var currentPrice = parseFloat(current.price)
      return currentPrice < prevPrice ? current : prev
    })

    setProductVariationRadioOptions(radioOptions)
    setSelectedProductVariation(selectedItem)
    setProductVariationSelectValue(selectedItem?.id)
  }, [data, isTablet])

  const handleModalOpenClose = () => {
    navigate(`/services/${slug}`)
  }

  const handleSupportClick = () => {
    navigate(
      `/services/${slug}/checkout?variant=${selectedProductVariation?.id}${
        referralCode ? "&referral=" + referralCode : ""
      }`
    )
  }

  const offer = data?.offer
    ? {
        ...data.offer,
        name: getValueForLocale(data.offer, "name", locale, defaultLocale),
        description: getValueForLocale(
          data.offer,
          "description",
          locale,
          defaultLocale
        ),
        shortDescription: getValueForLocale(
          data.offer,
          "shortDescription",
          locale,
          defaultLocale
        ),
      }
    : null

  const createOfferImages = (imageUrls) => {
    if (typeof imageUrls === "object" || Array.isArray(imageUrls)) {
      return imageUrls?.map((imageUrl, index) => ({
        id: index + 1,
        image: imageUrl,
        description: "",
      }))
    }

    return []
  }

  const offerImages = createOfferImages(Array.of(offer?.image))

  const offerImage = offerImages?.length
    ? `${offerImages[0].image}?width=600&height=317&quality=90`
    : offer?.image || logoImage

  const supportButtonText = formatMessage({
    id: "service::detail::buttons::buy",
    defaultMessage: "Buy",
  })

  const headerComponent = (
    <StyledDetailHeader>
      <DetailHeader
        headerClassName="detail-header"
        text={getValueForLocale(data?.offer, "name", locale, defaultLocale)}
      />
      {!isTablet && (
        <div>
          <FontAwesomeIcon icon={faShare} onClick={() => handleShare()} />
        </div>
      )}
    </StyledDetailHeader>
  )

  const handleShare = () => {
    try {
      if (navigator && navigator.share)
        navigator.share({
          title: offer?.name,
          url: location?.href || "https://trustmeup.com",
          text: offer?.name || "TrustmeUp",
        })
    } catch (err) {}
  }

  /* const getCountryLabel = useCallback(
    (countryCode) => {
      if (countryOptions?.length) {
        return countryOptions?.find(({ value }) => value === countryCode)
      }
    },
    [countryOptions]
  ) */

  const ProductVariationAction = useCallback(() => {
    const gridClassName =
      productVariationRadioOptions?.length > 3 ? "donation-grid-four" : ""

    const el =
      typeof window !== "undefined" && document.getElementsByTagName("body")[0]
    if (el) el.style.paddingBottom = 0

    return (
      <div
        style={{
          background: theme?.colors?.whiteSmoke,
          borderRadius: "0.5rem",
          padding: isWide ? "2.5rem" : isDesktop ? "1.25rem" : "1rem",
        }}>
        <>
          {productVariationRadioOptions.length > 0 && (
            <StyledProductVariationOptions className={gridClassName}>
              {productVariationRadioOptions.length > 1 && (
                <RadioSelect
                  items={productVariationRadioOptions}
                  defaultValue={productVariationSelectValue}
                  onChange={(e) => {
                    const selectedValue = e.target.value
                    if (selectedValue?.isPassive !== true) {
                      setProductVariationSelectValue(selectedValue)
                      const filteredData = productVariationRadioOptions.filter(
                        (i) => i.id == selectedValue
                      )
                      if (filteredData.length > 0) {
                        setSelectedProductVariation(filteredData[0])
                      }
                    }
                  }}
                />
              )}
              {statsComponent}
              <StyledSupportButton
                color="carrot"
                data-testid="support-service"
                onClick={handleSupportClick}
                disabled={selectedProductVariation?.isSoldOut}>
                {selectedProductVariation?.isSoldOut ? (
                  <FormattedMessage
                    id="event::ticket::soldOut"
                    defaultMessage="Sold Out"
                  />
                ) : (
                  supportButtonText
                )}
              </StyledSupportButton>
            </StyledProductVariationOptions>
          )}
        </>
      </div>
    )
  }, [
    offer,
    productVariationRadioOptions,
    productVariationSelectValue,
    locale,
    isTablet,
  ])

  const ServiceTabs = useCallback(() => {
    return (
      <StyledWrapper data-testid="service-detail-overview">
        <StyledContent>
          <StyledSectionTitle
            className="caption"
            data-testid="service-detail-overview-tab">
            <FormattedMessage
              id="serviceDetail::about"
              defaultMessage="About"
            />
          </StyledSectionTitle>
          <StyledDescription
            dangerouslySetInnerHTML={{ __html: offer?.description }}
          />
        </StyledContent>
      </StyledWrapper>
    )
  }, [offer?.description])

  const ServiceContactTabs = useCallback(() => {
    return (
      <>
        <StyledContactTitle>
          <FormattedMessage
            id="service::detail::contacts::title"
            defaultMessage="Contacts"
          />
        </StyledContactTitle>
        <div>
          <StyledPhoneBox>
            {offer?.store?.phoneNumber && <FontAwesomeIcon icon={faPhone} />}
            <span>{getFormatPhoneNumber(offer?.store?.phoneNumber)}</span>
          </StyledPhoneBox>
        </div>
        {offer?.addressLine1 || offer?.store?.defaultOffer?.addressLine1 ? (
          <StyledLocationBox>
            <FontAwesomeIcon icon={faMapMarkerAlt} />
            <div>
              <span>
                <p>
                  {[
                    // getCountryLabel(offer?.store?.country)?.label,
                    // offer?.store?.city,
                    offer?.addressLine1 ||
                      offer?.store?.defaultOffer?.addressLine1,
                    // offer?.store?.postCode,
                  ]
                    .filter((item) => item)
                    .join(", ")}
                </p>
              </span>
            </div>
          </StyledLocationBox>
        ) : null}
      </>
    )
  }, [offer?.store?.id])

  const ServiceStoreInfoTabs = useCallback(() => {
    return (
      <StyledStoreLogoContainer
        onClick={() => {
          navigate("/offers/store/" + offer?.store?.slug)
        }}>
        <StyledStoreLogo
          data-testid="service-detail-store-logo"
          src={getValueForLocale(offer?.store, "logo", locale, defaultLocale)}
        />
        <StyledStoreName>{offer?.store?.displayName}</StyledStoreName>
      </StyledStoreLogoContainer>
    )
  }, [offer?.store?.id])

  const toggleMapVisibility = () => {
    setIsMapVisible((prevIsMapVisible) => !prevIsMapVisible)
  }

  const locationForMap =
    offer?.location?.coordinates ||
    offer?.store?.defaultOffer?.location?.coordinates

  const ServiceMapTabs = () => {
    return locationForMap ? (
      <StyledMapMainContainer className="service-map">
        <StyledMapContainer>
          <StyledMapGetDirections>
            <Button
              data-testid={`btn-get-directions`}
              variant="text"
              type="button"
              size="small"
              onClick={() => {
                generateGoogleMapsLinkAndOpen(locationForMap, "_blank")
              }}>
              <FormattedMessage
                id="service::detail::map::getDirections"
                defaultMessage="Get Directions"
              />
            </Button>
          </StyledMapGetDirections>
          <StyledMapHideShow>
            <Button
              data-testid={`btn-map-hide-show`}
              variant="text"
              type="button"
              size="small"
              align="right"
              onClick={toggleMapVisibility}>
              <FormattedMessage
                id={`service::detail::map::${isMapVisible ? "hide" : "show"}`}
                defaultMessage={isMapVisible ? "Hide map" : "Show on map"}
              />
              <FontAwesomeIcon
                icon={isMapVisible ? faChevronUp : faChevronDown}
              />
            </Button>
          </StyledMapHideShow>
        </StyledMapContainer>
        {isMapVisible && (
          <iframe
            width="100%"
            height={
              isWide ? "624px" : isDesktop || isTablet ? "341px" : "210px"
            }
            style={{
              border: 0,
            }}
            loading="lazy"
            allowfullscreen
            referrerpolicy="no-referrer-when-downgrade"
            src={generateGoogleMapsEmbedLink(locationForMap, locale)}></iframe>
        )}
      </StyledMapMainContainer>
    ) : null
  }

  const descriptionToShare = offer?.shortDescription || offer?.description

  const statsComponent = (
    <>
      <OfferStats
        price={selectedProductVariation?.price}
        maxPacDiscount={
          selectedProductVariation?.pacDiscount ?? offer?.maxPacDiscount
        }
      />
    </>
  )

  const handleViewAll = () => {
    navigate(
      `/offers/?merchantType=0&offer=true&store=false&stores=${offer?.store?.id}`
    )
  }

  const voucherUserBeforeContent = (
    <>
      {offer?.isVoucher && offer?.endDate && (
        <div className="use-before">
          <Spacer top={1} />
          <UseBefore isVoucher={offer?.isVoucher} endDate={offer?.endDate} />
          <Spacer top={1} />
        </div>
      )}

      {offer?.isReservationRequired && (
        <div className="use-before">
          <StyledExpiryDate>
            <StyledUseBefore>
              <FormattedMessage
                id="purchase::success::reservationRequired"
                defaultMessage="Reservation Required"
              />
            </StyledUseBefore>
          </StyledExpiryDate>
          <Spacer top={1} />
        </div>
      )}
    </>
  )

  const customNotFoundMessage = (
    <h6>
      <FormattedMessage
        id="serviceDetail::noOfferMessage::title"
        defaultMessage="The store does not provide any services at the moment."
      />
    </h6>
  )

  return (
    <>
      <SEO
        lang={locale}
        title={offer?.name}
        description={descriptionToShare}
        image={offerImage}
        pathname={pathname}
      />
      {loading ? (
        <Spinner />
      ) : offer ? (
        <>
          {!isTablet ? (
            <>
              {isNewService ? (
                // mobile version new service
                <StyledCongratsMobile>
                  <div className="modal-content">
                    <span>
                      <FormattedMessage
                        id="campaign::detail::congrats::heading"
                        defaultMessage="Congrats!"
                        tagName="h2"
                      />
                      <FormattedMessage
                        id="service::detail::congrats::heading-mobile"
                        defaultMessage="Your service is ready"
                        tagName="h2"
                      />
                    </span>
                    <FormattedMessage
                      id="service::detail::congrats::paragraph"
                      defaultMessage="Share the service on your socials with friends"
                      tagName="p"
                    />
                  </div>
                  <div className="sticky-bottom-bar">
                    <hr />
                    <Button
                      type="button"
                      onClick={() => {
                        handleModalOpenClose()
                        handleShare()
                      }}>
                      <FormattedMessage
                        id="campaign::detail::congrats::shareButton"
                        defaultMessage="ok, let‘s share it"
                      />
                    </Button>
                  </div>
                </StyledCongratsMobile>
              ) : (
                // mobile version non-new service
                <StyledPageWrapper>
                  <DetailPageMobileContainer>
                    <MediaSlider
                      images={offerImages}
                      altName={`offer-detail-${offerImages}`}
                    />
                    {headerComponent}
                    {voucherUserBeforeContent}
                    <Spacer top={1.5} />
                    <ProductVariationAction />
                    <ServiceStoreInfoTabs />
                    <ServiceTabs />
                    <hr style={{ margin: "0 1rem", width: "unset" }} />
                    {offer?.showAddressAndPhone ? (
                      <>
                        <ServiceContactTabs />
                        <ServiceMapTabs />
                      </>
                    ) : null}
                    <ServiceListing
                      storeId={offer?.store?.id}
                      handleViewAll={handleViewAll}
                      hideDefaultOffer={true}
                      servicesToExclude={[offer?.id]}
                    />
                  </DetailPageMobileContainer>
                </StyledPageWrapper>
              )}
            </>
          ) : (
            // non-mobile version
            <StyledPageWrapper>
              <DetailPageContainer>
                <MainPart>
                  <MediaSlider
                    images={offerImages}
                    altName={`offer-detail-${offerImages}`}
                    video={offer?.video}
                  />
                  <ServiceTabs />
                  <hr />
                  {offer?.showAddressAndPhone ? (
                    <>
                      <ServiceContactTabs />
                      <ServiceMapTabs />
                    </>
                  ) : null}
                </MainPart>
                <SidePart
                  style={{
                    paddingTop: "unset",
                    backgroundColor: theme.colors.white,
                  }}>
                  <StyledHeaderAndShare>
                    <div>{headerComponent}</div>
                    <div>
                      <ShareComponent
                        offerId={offer?.id}
                        slug={slug}
                        isNewCampaign={isNewService}
                        name={offer?.name}
                        discount={offer?.store?.maxPacDiscount}
                        storeLogo={offer?.store?.logo}
                        position="center"
                      />
                    </div>
                  </StyledHeaderAndShare>
                  {voucherUserBeforeContent}
                  <ProductVariationAction />
                  <ServiceStoreInfoTabs />
                  {isNewService ? (
                    <StyledCongratsPopup>
                      <div className="modal-content">
                        <FormattedMessage
                          id="campaign::detail::congrats::heading"
                          defaultMessage="Congrats!"
                          tagName="h2"
                        />
                        <FormattedMessage
                          id="service::detail::congrats::paragraph"
                          defaultMessage="Share the service on your socials with friends"
                          tagName="p"
                        />
                      </div>
                      <FontAwesomeIcon
                        icon={faTimes}
                        onClick={handleModalOpenClose}
                      />
                    </StyledCongratsPopup>
                  ) : null}
                </SidePart>
              </DetailPageContainer>
              <ServiceListing
                storeId={offer?.store?.id}
                handleViewAll={handleViewAll}
                hideDefaultOffer={true}
                servicesToExclude={[offer?.id]}
                customNotFoundMessage={customNotFoundMessage}
              />
            </StyledPageWrapper>
          )}
        </>
      ) : (
        <ContentNotFound slug={slug} />
      )}
    </>
  )
}

ServiceDetail.propTypes = {
  slug: PropTypes.string.isRequired,
  location: PropTypes.object.isRequired,
}

export default ServiceDetail
