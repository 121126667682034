module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-PCGHVV9C","includeInDevelopment":true,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://001e81d13deb4f638389e601af0959e3@sentry.io/2319542","environment":"alpha","enabled":true,"release":"2021.10.26"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"TrustMeUp","short_name":"TMU","description":"TrustMeUp is the ecosystem where associations meet fans and donors to share goals and projects to be carried out together, but also to create a new economic model.","lang":"en","start_url":"/","background_color":"#00a0c0","theme_color":"#00a0c0","display":"minimal-ui","icon":"src/assets/images/logo-shape.png","crossOrigin":"use-credentials","cache_busting_mode":"none","localize":[{"start_url":"/it/","lang":"it","name":"TrustMeUp","short_name":"TMU","description":"TrustMeUp è l'ecosistema in cui le associazioni incontrano fan e donatori per condividere obiettivi e progetti da realizzare insieme, ma anche per creare un nuovo modello economico."},{"start_url":"/es/","lang":"es","name":"TrustMeUp","short_name":"TMU","description":"TrustMeUp es el ecosistema donde las asociaciones se encuentran con fans y donantes para compartir objetivos y proyectos a realizar juntos, pero también para crear un nuevo modelo económico."}],"legacy":true,"theme_color_in_head":true,"include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-loadable-components-ssr/gatsby-browser.js'),
      options: {"plugins":[],"useHydrate":false,"preloadTags":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://alpha.trustmeup.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-intercom-spa/gatsby-browser.js'),
      options: {"plugins":[],"app_id":"dt7elb43","include_in_development":true,"delay_timeout":4000},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/opt/build/repo/src/layouts/storefront.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/opt/build/repo/public/intl","languages":["en","it","es"],"defaultLanguage":"en","redirect":true,"redirectComponent":"/opt/build/repo/src/components/redirect.js"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
